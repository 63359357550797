import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import multiplierData from "./multiplier.json";
import axios from "axios";
import { url } from "../../../services/api_function";

const CommissionData = (props) => {
  const location = useLocation();
  const phoneNumberFromUrl = new URLSearchParams(location.search).get("user");
  const [user, setUser] = useState(phoneNumberFromUrl);
  const [userData, setUserData] = useState([]);
  const [apiTimestamp, setApiTimestamp] = useState(null);
  const isInitialRender = useRef(true);
  const User = useMemo(() => user, [user]);
  const [currentValue, setCurrentValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [inputRef, setInputRef] = useState("");
  const [inputDay, setInputDay] = useState();
  const [ethToUsdRate, setEthToUsdRate] = useState(null);
  const [share, setShare] = useState(null);
  const [amount, setAmount] = useState(null);
  const [purchase, setPurchase] = useState(null);
  const [timeOfInsurace, setTimeOfInsurance] = useState(null);
  const [premium, setPremium] = useState(null);
  const [exist, setExist] = useState(null);
  const [idFromURL, setIdFromURL] = useState(null);
  const [personalShare, setPersonalShare] = useState(0);
  const [insuranceActive, setInsuranceActive] = useState("");
  const [lastBought, setLastBought] = useState();
  const [insuranceEndTime, setInsuranceEndTime] = useState();
  const [refby, setRefby] = useState("");
  const [insure, setInsure] = useState(0);
  const [total_share, setTotal_share] = useState();

  const getLastPurchaseanddetails = async () => {
    try {
      const res = await axios.post(url + "/get_last_purchase", {
        userAddress: User,
      });
      console.log(res, "res from get_last_purchase currently working");
      if (res.status == 200) {
        setInsuranceEndTime(
          new Date(res.data.lastPurchase.endTime * 1000).toLocaleString("en-gb")
        );
        setPersonalShare(res.data.lastPurchase.amount);
        // setRefby(res.data.lastPurchase.referadBy);
        console.log(
          res.data.lastPurchase.referadBy,
          "setting in reffff console"
        );
        let currentDate = new Date();
        let endDate = new Date(res.data.lastPurchase.endTime * 1000);
        let last = res.data.lastPurchase.time;
        let lastBought = new Date(last);
        let day = lastBought.getDate();
        let month = lastBought.getMonth() + 1;
        let year = lastBought.getFullYear();
        let formattedDay = day < 10 ? "0" + day : day;
        let formattedMonth = month < 10 ? "0" + month : month;
        let formattedDate = formattedDay + "-" + formattedMonth + "-" + year;
        setLastBought(formattedDate);
        // console.log(formattedDate,":::::"); // Output: "07-02-2024"
        if (endDate > currentDate) {
          setInsuranceActive("Active");
        } else {
          setInsuranceActive("InActive");
        }

        const duration = res?.data?.lastPurchase?.duration;
        const value = res?.data?.lastPurchase?.payAmount / 1e18;
        const obj = multiplierData.data.find((item) => {
          return item.days == duration;
        });
        setInsure(value * obj?.multiplier);
        setTotal_share(res?.data?.totalAmount);
        console.log(duration, value, obj, value * obj?.multiplier, ":duration");
      } else {
        setPersonalShare(0);
        setInsuranceActive("");
        setLastBought("");
        setInsuranceEndTime("");
        setRefby("");
        setTotal_share("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLastPurchaseanddetails();
  }, [user]);


  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Commission
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong> Your shares</strong>
                    </th>
                    <th>
                      <strong> Last Bought Share</strong>
                    </th>
                    <th>
                      <strong> Insured Amount</strong>
                    </th>
                    <th>
                      <strong> Insurance Active</strong>
                    </th>
                    {/* <th>
                      <strong> Referred by</strong>
                    </th> */}
                    <th>
                      <strong> Last bought</strong>
                    </th>
                    <th>
                      <strong> Insurance EndTime</strong>
                    </th>
                    <th>
                      <strong> Value In Eth</strong>
                    </th>
                    {/* <th>
                      <strong> Insurance Unit</strong>
                    </th>
                    <th>
                      <strong>Team Business</strong>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {total_share > 0 ? total_share : 0}</td>
                    <td>
                      {" "}
                      {personalShare > 0
                        ? Number(personalShare).toFixed(2)
                        : "0"}
                    </td>
                    <td>
                      {insure > 0 && insuranceActive == "Active"
                        ? insure.toFixed(9)
                        : 0}
                    </td>
                    <td> {insuranceActive}</td>
                    {/* <td>{refby != ""
                ? refby?.slice(0, 3) + "..." + refby?.slice(-4)
                : "-"}</td>  */}
                    <td>   {lastBought}</td>
                    <td>    {insuranceEndTime}</td>
                    <td> {amount && amount > 0
                ? (amount / 1e18).toString().slice(0, 9)
                : "0"}</td>
                    {/* <td>{(userData?.users?.wallet_roi ?? 0).toFixed(2)}</td>
                    <td>{(userData?.teamBussines ?? 0).toFixed(2)}</td> */}
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CommissionData;
